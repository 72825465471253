.cds--content {
  padding: 3rem 0rem 0rem 0rem;
  min-height: 100vh;
}

#WACContainer.WACContainer .WACWidget.WACWidget--launched.WACWidget--defaultElement {
  height: 75% !important;
}

.homepage-container {
  h5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .list {
    font-size: 15px;
    margin-bottom: 2rem;
  }

  .map {
    width: 100%;
    display: flex;

    .h51 {
      width: 50%;
      font-size: 15px;
      font-weight: 300;
      font-style: italic;
    }

    .h52 {
      width: 35%;
      font-size: 15px;
      font-weight: 300;
      font-style: italic;
    }
  }

  // .washing-machine {
  //   background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRe_1g7GArm7lLKJBaVihqUrRbUOAcf4zCSacjzK49-JprFYTOv5mRL2cWkGNnlh4N-y4&usqp=CAU"
  //     );
  //   background-size: cover;
  //   min-height: 250px;
  // }

  .cds--tile {
    margin-bottom: 1rem;
  }
}